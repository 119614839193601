import React, {useEffect, useState} from 'react';
import '../../CSS/Login_new.css';
import '../../CSS/Start_page_new.css';
import '../../../languages/i18n';
import ProfileNew from "../Profile_new";
import {useTranslation} from "react-i18next";
import {PulseLoader} from "react-spinners";

function Mobile_profile() {
    const {t} = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get('email');
        const uid = queryParams.get('uid');
        const photoURL = queryParams.get('photoURL');
        const providerId = queryParams.get('providerId');

        localStorage.setItem('uid', uid);
        localStorage.setItem('email', email);
        localStorage.setItem('photoURL', photoURL);
        localStorage.setItem('providerId', providerId);

        setIsLoaded(true);
    }, []);



    return (
        <div className="page-container">
            {isLoaded ? (
                <ProfileNew/>
            ) : (
                <div className="loading-spinner">
                    <PulseLoader color="#005CEF" loading={true} size={10} />
                </div>
            )}
        </div>
    );
}

export default Mobile_profile;