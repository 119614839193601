import Acquiring from "./Acquiring";

const SubscriptionCancelledBlock = ({ shopData }) => {
    const userEmail = localStorage.getItem("email") || "";
    return (
        <div className="cancelled-description-user-console profile-block-main">
            <div className="title-profile-block-main">
                <div className="group-elements-title-profile-main">
                    <h1>Подписка</h1>
                    <div className="status-title-profile-main">
                        <span>Отменена</span>
                    </div>
                </div>
                <hr />
            </div>
            <div className="content-block-cancelled-description content-profile-block-main">
                <table className="cost-description-cancelled-description">
                    <tbody>
                    <tr>
                        <td><h1>Возобновить подписку</h1></td>
                        <td className="cost"><span>&nbsp;</span></td>
                    </tr>
                    {shopData.success ? (
                        <>
                            {shopData.firstPayment !== shopData.monthlyPayment && (
                                <tr>
                                    <td><span>Первый платёж</span></td>
                                    <td className="cost"><span>{shopData.firstPayment} ₽</span></td>
                                </tr>
                            )}
                            <tr>
                                <td><span>Последующие платежи</span></td>
                                <td className="cost"><span>{shopData.monthlyPayment} ₽</span></td>
                            </tr>
                        </>
                    ) : (
                        <tr><td><span>Загрузка цен..</span></td></tr>
                    )}
                    </tbody>
                </table>
                <Acquiring
                    firstPrice={shopData.firstPayment}
                    subPrice={shopData.monthlyPayment}
                    userEmail={userEmail}
                />
            </div>
        </div>
    );
};

export default SubscriptionCancelledBlock;
