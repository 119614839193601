import React, {useEffect, useRef, useState} from "react";
import {ToastContainer} from 'react-toastify';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

import {CgArrowRight, CgChevronDown} from "react-icons/cg";

import "../CSS/Start_page_new.css";
import "../CSS/Start_page_new_mobile.css";
import '../../languages/i18n';

import { ReactComponent as Android } from "../Imagines/Android.svg";
import { ReactComponent as AppStore } from "../Imagines/AppStore.svg";
import { ReactComponent as Windows } from "../Imagines/Windows.svg";

function StartPage_new() {
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n, t } = useTranslation('mainPage');
    const defaultLanguage = (i18n.language || navigator.language).split('-')[0];
    const [activeLanguage, setActiveLanguage] = useState(defaultLanguage);
    const languages = ['ru', 'en', 'kk', 'zh'];
    const languageNames = {
        ru: 'Ru',
        en: 'En',
        kk: 'Kk',
        zh: 'Zh'
    };
    const otherLanguages = languages.filter((lang) => lang !== activeLanguage);
    const [listLanguageCollapsed, setListLanguageCollapsed] = useState(true);
    const descriptions = t('Description', {returnObjects: true});
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [shopData, setShopData] = useState([]);

    const elementsRef = useRef([]);
    const BuyScroll = useRef(null);

    // const Ref = useRef([]);

    //Получаем дефолтные цены
    useEffect(() => {
        const requestData = {
            uid: "",
            email: "",
        };
        fetchShopData(requestData);
    }, []);


    //Сохраняем токен реферальной ссылки
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");

        if (token) {
            localStorage.setItem("promo", token);
        }
    }, [location.search]);


    //Прозрачность при листании основного текста
    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;

            elementsRef.current.forEach((element) => {
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const elementCenter = (rect.top + rect.bottom) / 2;
                    const distanceFromCenter = Math.abs(windowHeight / 2 - elementCenter);

                    const maxDistance = Math.max(windowHeight / 2 - 50, 150);
                    const minDistance = Math.max(windowHeight / 2 - 250, 100);
                    if (distanceFromCenter < minDistance) {
                        element.style.opacity = 1
                        // element.style.color = "red"
                    } else if (distanceFromCenter > minDistance && distanceFromCenter < maxDistance) {
                        element.style.opacity = 1 - (distanceFromCenter - minDistance) / (maxDistance - minDistance);
                        // element.style.color = "green"
                    } else {
                        element.style.opacity = 0
                        // element.style.color = "black"
                    }
                }
            });
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    //Работа языкового свитчера
    useEffect(() => {
        const handleScroll = () => {
            setListLanguageCollapsed(true);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const changeLanguage = (language) => {
        setActiveLanguage(language);
        i18n.changeLanguage(language);
        setListLanguageCollapsed(true);
    };
    const languageSwitching = () => {
        setListLanguageCollapsed((prevState) => !prevState);
    };


    //Запрос на цены
    const fetchShopData = (userData) => {
        fetch(`https://gostlink.com/api/shop/getShop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
            .then(response => {
                console.log(response);
                return response.text();
            })
            .then(text => {
                console.log(text);
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                console.log(data);
                if (data.success) {
                    setShopData(data);
                } else {
                    navigate(`/`);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                navigate(`/`);
            });
    };


    //Прокрутка к блоку с ценой
    const scrollToTarget = () => {
        if (BuyScroll.current) {
            BuyScroll.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
        });
        }
    };


    //Переход в поддержку
    const handleTelegramClick = () => {
        const email = encodeURIComponent(localStorage.getItem('email'));
        window.open(`https://t.me/gostlink_support_bot?start=${email}`, '_blank');
    };


    //Выделение недоступных платформ
    const handleHighlight = () => {
        setIsHighlighted(true);
        setTimeout(() => {
            setIsHighlighted(false);
        }, 7000);
    };



    return (
        <div className="page-container">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />
            <section className="section-main title-site">
                <div className="background-main background-title-site">
                    <div className="gradient-main gradient-title-site"></div>
                    <div className="noise-main noise-title-site"/>
                </div>

                <div className="header-buttons">
                    <button className="button-line-main button-enter-title-site"
                            onClick={() => {navigate('/login')}}
                    >
                        <span>
                            {t('header.login')}
                        </span>
                        <CgArrowRight style={{marginRight: '-7px'}} size={28}/>
                    </button>

                    <div className={`language-switcher-title-site ${listLanguageCollapsed ? '' : 'active'}`}
                         // onMouseEnter={() => setListLanguageCollapsed(false)}
                         onMouseLeave={() => setListLanguageCollapsed(true)}>
                        <button className={`button-line-main button-languages-title-site ${listLanguageCollapsed ? 'collapsed' : ''}`}
                                onClick={() => languageSwitching()}>
                            <span>
                                {languageNames[activeLanguage]}
                            </span>
                        </button>
                        {otherLanguages.map((lang) => (
                            <button className="button-line-main button-languages-title-site" key={lang}
                                    onClick={() => changeLanguage(lang)}>
                                <span>
                                    {languageNames[lang]}
                                </span>
                            </button>

                        ))}
                    </div>
                </div>

                <div className="title-group">
                    <div className='title-string'>
                        <span className="title">
                            GOSTLINK
                        </span>
                        <span className="sign">
                            (VPN)
                        </span>
                        <div className="red-point-main red-point-title"></div>
                    </div>
                    <span className="manifest">
                        {t('mainSection.manifest')}
                    </span>
                    <button className="button-line-main button-to-prise-line-main"
                            onClick={scrollToTarget}>
                        <span>
                            {t('mainSection.buy')}
                        </span>
                        <CgChevronDown className="chevron-icon" />
                    </button>
                </div>
            </section>

            <section className="section-main product-description-block">
                <div className="background-main background-product-description">
                    <div className="gradient-main gradient-product-description"></div>
                    <div className="noise-main noise-product-description"/>
                </div>

                <div className="product-description-content">
                    {descriptions.map((item, index) => (
                        <div className="product-description-element" key={index}
                             ref={(el) => (elementsRef.current[index] = el)}>
                            <div className="title-description-element">
                                <div className="red-point-main"></div>
                                <span className="title-text-description-element">
                                    {item.title}
                                </span>
                            </div>
                            <p className="description-element-text">
                                {item.text}
                            </p>
                        </div>
                    ))}
                </div>
            </section>

            <section className="product-info-section section-main">
                <div className="background-product-info background-main">
                    <div className="gradient-product-info gradient-main"></div>
                    <div className="noise-product-info noise-main"/>
                </div>

                <div ref={BuyScroll} style={{position: "absolute", top: "-50px"}}></div>

                <div className="content-product-prise">
                    <div className='title-cost-string-product-prise'>
                        <span className="title-title-cost-string">
                            {t('pricing.title')}
                        </span>
                        <span className="sign-title-cost-string">
                            ({t('pricing.subscription')})
                        </span>
                    </div>

                    <table className="payments-list-product-prise">
                        <tbody>
                        {shopData.success ? (
                            <>
                                {shopData.firstPayment !== shopData.monthlyPayment && (
                                    <tr>
                                        <td colSpan="2">
                                                <span className="sign-payments-list">
                                                    {t('pricing.firstPayment')}
                                                </span>
                                        </td>
                                        <td>
                                                <span className="price-payments-list">
                                                    {shopData.firstPayment} ₽
                                                </span>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        {shopData.monthlyPayment !== shopData.strikethroughPrice && shopData.discount ? (
                                            <span className="sign-payments-list">
                                                    {t('pricing.discount', { discount: shopData.discount })}
                                                </span>
                                        ) : (
                                            <span className="sign-payments-list">
                                                    {t('pricing.allPayments')}
                                                </span>
                                        )}
                                    </td>
                                    <td style={{width: "100%", textAlign: "right"}}>
                                            <span className="sign-payments-list" style={{marginRight: "30px", textDecoration: "line-through", textDecorationColor: "#E40000"}}>
                                                &nbsp;{shopData.strikethroughPrice} ₽&nbsp;
                                            </span>
                                    </td>
                                    <td>
                                            <span className="price-payments-list">
                                                {shopData.monthlyPayment} ₽
                                            </span>
                                    </td>
                                    <td>
                                        <div className="red-point-main" style={{marginLeft: "10px"}}></div>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr><td><span className="sign-payments-list">
                                    {t('pricing.loading')}
                                </span></td></tr>
                        )}
                        </tbody>
                    </table>

                    <div className="benefit-list-product-prise">
                        <h1>{t('pricing.benefits.title')}</h1>
                        {(t('pricing.benefits.items', { returnObjects: true }) || []).map((item, index) => (
                            <span key={index}>
                                {item}<span>—</span>
                            </span>
                        ))}
                    </div>

                    <button className="button-line-main button-buy-product-prise"
                            onClick={() => {navigate('/login')}}>
                        <span>
                            {t('pricing.buy')}
                        </span>
                    </button>
                </div>


                <div className="content-product-app">
                    <h1 className="title-product-app">
                        {t('download.title')}
                    </h1>
                    <div className="buttons-download-product-app">
                        <button className="button-line-main button-product-app "
                                onClick={() => window.open('https://play.google.com/store/apps/details?id=ru.gostlink.xray', '_blank')}>
                            <Android/>
                        </button>
                        <button className="button-line-main button-product-app"
                                onClick={handleHighlight}>
                            <AppStore/>
                            <div className="red-point-main red-point-product-app"></div>
                        </button>
                        <button className="button-line-main button-product-app"
                                onClick={handleHighlight}>
                            <Windows/>
                            <div className="red-point-main red-point-product-app"></div>
                        </button>
                    </div>
                    <div className={`sign-marker-product-app ${isHighlighted ? 'highlight' : ''}`}>
                        <div className="red-point-main"></div>
                        <span>
                            {t('download.inDevelopment')}
                        </span>
                    </div>
                </div>

                <div className="content-support">
                    <h1 className="title-support">
                        {t('support.title')}
                    </h1>
                    <div className="button-block-support">
                        <div className="sign-support">
                            <h1>
                                {t('support.contact')}
                            </h1>
                            <span>
                                {t('support.available')}
                            </span>
                        </div>
                        <button className="button-line-main button-support"
                                onClick={handleTelegramClick}>
                            <span>
                                Telegram
                            </span>
                        </button>
                        <button className="button-line-main button-support"
                                onClick={() => {window.location.href = 'mailto:info@gostlink.ru'}}>
                            <span>
                                info@gostlink.ru
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <section className="footer-section section-main">
                <div className="background-footer background-main">
                    <div className="gradient-footer gradient-main"></div>
                    <div className="noise-footer noise-main"></div>
                </div>
                <hr/>
                <div className="content-footer">
                    <span>
                        {t('footer.copyright')}
                    </span>
                    <button className="button-line-main button-footer"
                            onClick={() => {navigate('/offer')}}>
                        <span>
                            {t('footer.offer')}
                        </span>
                    </button>
                </div>
            </section>
        </div>

    );
}

export default StartPage_new;