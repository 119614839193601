import React from "react";
import i18n from "../../../languages/i18n";
import { eventGoogleAnalytics } from "../../../registration/analytics";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Acquiring = ({ firstPrice, subPrice, userEmail }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handlePayment = () => {
        var widget = new window.cp.CloudPayments({
            language: i18n.language === "ru" ? "ru-RU" : "en-US",
            email: userEmail,
            applePaySupport: false,
            googlePaySupport: false,
            yandexPaySupport: false,
            tinkoffPaySupport: true,
            tinkoffInstallmentSupport: false,
            sbpSupport: true,
        });

        var receipt = {
            Items: [
                {
                    label: t("Payment.Label"),
                    price: firstPrice,
                    quantity: 1.0,
                    amount: firstPrice,
                    vat: 0,
                    method: 0,
                    object: 0,
                },
            ],
            taxationSystem: 0,
            email: userEmail,
            phone: "",
            isBso: false,
            amounts: {
                electronic: firstPrice,
                advancePayment: 0.0,
                credit: 0.0,
                provision: 0.0,
            },
        };

        var receiptRecurrent = {
            Items: [
                {
                    label: t("Payment.Label"),
                    price: subPrice,
                    quantity: 1.0,
                    amount: subPrice,
                    vat: 0,
                    method: 0,
                    object: 0,
                },
            ],
            taxationSystem: 0,
            email: userEmail,
            phone: "",
            isBso: false,
            amounts: {
                electronic: subPrice,
                advancePayment: 0.0,
                credit: 0.0,
                provision: 0.0,
            },
        };

        var data = {
            CloudPayments: {
                CustomerReceipt: receipt,
                recurrent: {
                    interval: "Month",
                    period: 1,
                    customerReceipt: receiptRecurrent,
                },
            },
        };

        widget.charge(
            {
                publicId: "pk_4a8b35334f78ae1f4ca1dbd3650c4",
                description: t("Payment.Description"),
                amount: firstPrice,
                currency: "RUB",
                accountId: userEmail,
                data: data,
            },
            function () {
                eventGoogleAnalytics("purchase", "shop", "purchase", firstPrice);
                navigate("/profile");
            },
            function (reason) {
                // toast.error(reason);
            }
        );
    };

    return (
        <button className="button-user-console-main" onClick={handlePayment}>
            Купить
        </button>
    );
};

export default Acquiring;
