import React, {useState} from "react";

import { ReactComponent as Android } from "../../Imagines/Android.svg";
import { ReactComponent as AppStore } from "../../Imagines/AppStore.svg";
import { ReactComponent as Windows } from "../../Imagines/Windows.svg";

const AppDownloadBlock = () => {
    const [isHighlighted, setIsHighlighted] = useState(false);


    const handleHighlight = () => {
        setIsHighlighted(true);
        setTimeout(() => {
            setIsHighlighted(false);
        }, 7000);
    };
    return (
        <div className="product-app-user-console profile-block-main">
            <div className="title-profile-block-main">
                <div className="group-elements-title-profile-main">
                    <div className="wrapper-title-main">
                        <h1>Установить приложение</h1>
                    </div>
                </div>
                <hr />
            </div>
            <div className="content-block-product-app content-profile-block-main">
                <div className="buttons-line-product-app">
                    <button className="button-download-app-product-app button-user-console-main">
                        <Android/>
                    </button>
                    <button className="button-download-app-product-app button-user-console-main" onClick={handleHighlight}>
                        <AppStore/>
                        <div className="red-point-console-main red-point-console-product-app"></div>
                    </button>
                    <button className="button-download-app-product-app button-user-console-main" onClick={handleHighlight}>
                        <Windows/>
                        <div className="red-point-console-main red-point-console-product-app"></div>
                    </button>
                </div>
                <div className={`marker-product-app ${isHighlighted ? 'highlight' : ''}`}>
                    <div className="red-point-console-main"></div>
                    <span>Приложение находится в разработке</span>
                </div>
            </div>
        </div>
    );
};

export default AppDownloadBlock;
