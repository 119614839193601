import { CgArrowRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

const ReferralBlock = () => {
    const navigate = useNavigate();

    return (
        <div className="referral-block-user-console profile-block-main">
            <div className="group-elements-title-profile-main">
                <div className="signature-referral">
                    <div className="red-point-console-main"></div>
                    <h1>Реферальная программа</h1>
                </div>
                <button
                    className="button-user-console-main"
                    style={{marginLeft: 'auto'}}
                    onClick={() => navigate('/promo')}
                >
                    <span>Подробнее</span>
                    <CgArrowRight style={{ marginRight: '-7px' }} size={42} />
                </button>
            </div>
        </div>
    );
};

export default ReferralBlock;