import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Acquiring from "./Acquiring";
import {useState} from "react";

const ShopBlock = ({ shopData, refreshShopData }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const userEmail = localStorage.getItem("email") || "";

    const [promoCode, setPromoCode] = useState("");
    const [msgFromPromo, setMsgFromPromo] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showMessage, setShowMessage] = useState(false);


    const fetchPromo = () => {
        const promoData = {
            email: localStorage.getItem('email'),
            promo: promoCode,
        };

        fetch(`https://gostlink.com/api/shop/applyPromo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(promoData),
        })
            .then(response => response.text())
            .then(text => {
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                console.log(data)
                if (data.success) {
                    const requestData = {
                        uid: localStorage.getItem('uid'),
                        email: localStorage.getItem('email'),
                    };
                    refreshShopData(requestData);
                } else {
                    setMsgFromPromo(data.message);
                    setIsButtonDisabled(true);

                    setTimeout(() => {
                        setMsgFromPromo('');
                        setIsButtonDisabled(false);
                    }, 3000);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                navigate(`/`);
            });
    };

    const handleClick = (e) => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    };

    return (
        <div className="sale-block-user-console profile-block-main">
            <div className="title-profile-block-main">
                <div className="group-elements-title-profile-main">
                    <div className="wrapper-title-main">
                        <h1>Оформить подписку</h1>
                    </div>
                </div>
                <hr />
            </div>
            <div className="content-block-sale-block content-profile-block-main">
                <div className="content-section-sale-block">
                    <table className="cost-description-sale-block">
                        <tbody>
                        {shopData.success ? (
                            <>
                                {shopData.firstPayment !== shopData.monthlyPayment && (
                                    <tr>
                                        <td><span>Первый платёж</span></td>
                                        <td className="cost"><span>{shopData.firstPayment} ₽</span></td>
                                    </tr>
                                )}
                                <tr>
                                    <td><span>Последующие платежи</span></td>
                                    <td className="cost"><span>{shopData.monthlyPayment} ₽</span></td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td><span>Загрузка цен..</span></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="content-section-sale-block">
                    <div className="cost-benefits-sale-block">
                        <h1>Преимущества подписки:</h1>
                        <span>—&nbsp;&nbsp;&nbsp;Безлимит трафика</span>
                        <span>—&nbsp;&nbsp;&nbsp;Без ограничений скорости</span>
                        <span>—&nbsp;&nbsp;&nbsp;Доступ к иностранным сервисам</span>
                        <span>—&nbsp;&nbsp;&nbsp;Работа с российскими банками</span>
                        <span>—&nbsp;&nbsp;&nbsp;Отсутствие рекламы</span>
                    </div>
                </div>
                <div className="content-section-row-sale-block">
                    <h1>Промокод: </h1>

                    {shopData.promoActivated === false ? (
                        <div className="promo-form-sale-block">
                            <input className="promo-input-sale-block"
                                   type="text"
                                   value={promoCode}
                                   onChange={(e) => {
                                       let newValue = e.target.value.toUpperCase().replace(/[^A-Z0-9_-]/g, '');
                                       setPromoCode(newValue);
                                   }}
                                   inputMode="text"
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter') {
                                           fetchPromo();
                                       }
                                   }}
                            />
                            <button className="promo-button-sale-block button-user-console-main"
                                    onClick={() => fetchPromo()}
                                    disabled={isButtonDisabled}>
                                Применить
                            </button>

                            <div className="promo-sign-sale-block">
                                <span>{msgFromPromo}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="promo-form-sale-block">
                            <input className="promo-input-sale-block ok" value={shopData.promoCode} readOnly/>
                            <button className="promo-button-sale-block ok button-user-console-main"
                                    onClick={handleClick}>
                                Применён
                            </button>
                            {showMessage && (
                                <div className="promo-sign-sale-block ok">
                                    <span>Нельзя изменить применённый промокод</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Acquiring
                    firstPrice={shopData.firstPayment}
                    subPrice={shopData.monthlyPayment}
                    userEmail={userEmail}
                />
            </div>
        </div>
    );
};

export default ShopBlock;
