import React, {useRef, useState} from "react";

const HoldButton = ({ target, className, text }) => {
    const [isHolding, setIsHolding] = useState(false);
    const [progress, setProgress] = useState({});
    const holdTimers = useRef({});

    const handlePressStart = () => {
        setIsHolding(true);
    };

    const handlePressEnd = () => {
        setTimeout(() => {
            setIsHolding(false);
        }, 500);
    };

    const handleMouseDown = () => {
        const startTime = Date.now();

        holdTimers.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const duration = 2000;

            if (elapsedTime >= duration) {
                clearInterval(holdTimers.current);
                setProgress(100 );
                target();
            } else {
                const linearProgress = (elapsedTime / duration) * 100;
                setProgress(prev => (linearProgress));
            }
        }, 16);
    };

    const handleMouseUpOrLeave = () => {
        if (holdTimers.current) {
            clearInterval(holdTimers.current);
            holdTimers.current = null;
        }
        setProgress(0);
    };

    return (
        <button
            className={`${className} button-user-console-main`}
            style={{ position: "relative", overflow: "hidden", userSelect: "none" }}
            onMouseDown={() => {
                handleMouseDown();
                handlePressStart();
            }}
            onMouseUp={() => {
                handleMouseUpOrLeave();
                handlePressEnd();
            }}
            onMouseLeave={() => handleMouseUpOrLeave()}
            onTouchStart={() => {
                handleMouseDown();
                handlePressStart();
            }}
            onTouchEnd={() => {
                handleMouseUpOrLeave();
                handlePressEnd();
            }}
        >
            <div className="button-progress-user-console-main" style={{ width: `${progress || 0}%` }}></div>
            <div className="button-text-container">
                <span className={`button-text-main main-text ${isHolding ? "fade-up" : "fade-main"}`}>{text}</span>
                <span className={`button-text-main hold-text ${isHolding ? "fade-main" : "fade-down"}`}>Удерживайте</span>
            </div>
        </button>
    );
};

export default HoldButton;