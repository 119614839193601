import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import Apple_logo_device from "../../Imagines/Apple-logo-device.svg";
import Android_logo_device from "../../Imagines/Android-logo-device.svg";
import Windows_logo_device from "../../Imagines/Windows-logo-device.svg";
import Default_logo_device from "../../Imagines/Default-logo-device.svg";

import HoldButton from "./Hold_button";

const DevicesBlock = ({ deviceData, availableDevices, setDeviceData, setAvailableDevices }) => {

    const logos = {
        ios: Apple_logo_device,
        android: Android_logo_device,
        windows: Windows_logo_device,
        default: Default_logo_device
    };

    const fetchDetachDevice = (id) => {
        const requestData = {
            uid: localStorage.getItem("uid"),
            email: localStorage.getItem("email"),
            deviceId: id,
        };

        fetch("https://gostlink.com/api/profile/detachDevice", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
            .then(response => response.text())
            .then(text => {
                const data = JSON.parse(text);
                if (data.success) {
                    setDeviceData(data.devices);
                    setAvailableDevices(data.availableDevices);
                } else {
                    toast("Не удалось открепить устройство.");
                }
            })
            .catch(error => {
                toast("Не удалось открепить устройство.");
                console.log(error);
            });
    };

    return (
        <div className="devices-user-console profile-block-main">
            <div className="title-profile-block-main">
                <div className="group-elements-title-profile-main">
                    <h1>Устройства</h1>
                    <div className="status-title-profile-main"
                         style={{marginLeft: 'auto'}}>
                        <span>
                            {availableDevices === 0 ? "Достигнут лимит устройств" :
                                deviceData.length !== 0 ? `Незарегистрированных устройств: ${availableDevices}` :
                                    `Доступно устройств: ${availableDevices}`}
                        </span>
                    </div>
                </div>
                {deviceData.length !== 0 && <hr />}
            </div>
            {deviceData.length !== 0 && (
                <div className="content-block-devices content-profile-block-main">
                    {deviceData.map((device, index) => (
                        <React.Fragment key={device.id}>
                            <div className="device-unit-devices">
                                <div className="device-logo-devices">
                                    <img src={logos[device.platform] || logos.default} style={device.platform === "ios" ? { top: "-4px" } : {}} />
                                </div>
                                <div className="device-info-devices">
                                    <div className="name-data-string-devices">
                                        <span className="title-device-devices">
                                            {device.deviceName.length > 23 ? `${device.deviceName.slice(0, 20)}...` : device.deviceName}
                                        </span>
                                        <span className="text-device-devices">
                                            {new Date(device.firstActivation).toLocaleDateString("ru-RU")}
                                        </span>
                                    </div>
                                    <span className="text-device-devices">
                                        {device.deviceId.length > 23 ? `${device.deviceId.slice(0, 20)}...` : device.deviceId}
                                    </span>
                                </div>
                                <div className="device-detach-devices">
                                    <HoldButton
                                        target={() => fetchDetachDevice(device.id)}
                                        className={'button-detach-devices'}
                                        text={'Открепить'}
                                    />
                                </div>
                            </div>
                            {index !== deviceData.length - 1 && <hr className="separator-devices" />}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DevicesBlock;
