const SupportBlock = () => {

    const handleTelegramClick = () => {
        const email = encodeURIComponent(localStorage.getItem('email'));
        window.open(`https://t.me/gostlink_support_bot?start=${email}`, '_blank');
    };

    return (
        <div className="support-block-user-console profile-block-main">
            <div className="group-elements-title-profile-main">
                <h1>Поддержка</h1>
                <div className="buttons-line-support-block">
                    <button className="button-support-support-block button-user-console-main"
                            onClick={handleTelegramClick}>
                        Telegram
                    </button>
                    <button className="button-support-support-block button-user-console-main"
                            onClick={() => {window.location.href = 'mailto:info@gostlink.ru'}}>
                        info@gostlink.ru
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SupportBlock;
