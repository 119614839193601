import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ruOldSite from './ru/old_site.json';
import ruMainPage from './ru/main_page.json';

import enOldSite from './en/old_site.json';
import enMainPage from './en/main_page.json';

import zhOldSite from './zh/old_site.json';
import zhMainPage from './zh/main_page.json';

import kkOldSite from './kk/old_site.json';
import kkMainPage from './kk/main_page.json';

i18n
    .use(LanguageDetector) // используем LanguageDetector для автоматического определения языка
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                oldSite: ruOldSite,
                mainPage: ruMainPage
            },
            en: {
                oldSite: enOldSite,
                mainPage: enMainPage
            },
            zh: {
                oldSite: zhOldSite,
                mainPage: zhMainPage
            },
            kk: {
                oldSite: kkOldSite,
                mainPage: kkMainPage
            }
        },
        fallbackLng: 'en',
        detection: {
            order: ['localStorage', 'cookie', 'navigator'],
            caches: ['localStorage', 'cookie']
        },
        ns: ['oldSite', 'mainPage'],
        defaultNS: 'oldSite',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;