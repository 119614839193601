import { useNavigate } from "react-router-dom";

const SubscriptionActiveBlock = ({ userData }) => {
    const navigate = useNavigate();

    return (
        <div className="active-description-user-console profile-block-main">
            <div className="title-profile-block-main">
                <div className="group-elements-title-profile-main">
                    <h1>Подписка</h1>
                    <div className="status-title-profile-main">
                        <span>Активна</span>
                    </div>
                </div>
                <hr />
            </div>
            <div className="content-block-active-description content-profile-block-main">
                <div className="first-pay-active-description">
                    <span>
                        Следующее списание:&nbsp;&nbsp;{new Date(userData.nextTransactionDate).toLocaleDateString('ru-RU')}
                    </span>
                    <div className="status-title-profile-main">
                        <span>{userData.amount} ₽</span>
                    </div>
                </div>
                <button className="cancel-description-active-description" onClick={() => navigate('/cancel')}>
                    Отменить подписку
                </button>
            </div>
        </div>
    );
};

export default SubscriptionActiveBlock;
