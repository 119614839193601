import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

import profile from "../../Imagines/Photo-profile.svg"

const UserInfoBlock = () => {
    const navigate = useNavigate();

    const email = localStorage.getItem("email");
    const subscriptionIsActive = localStorage.getItem("subscriptionIsActive") === "true";
    const expirationDate = localStorage.getItem("expirationDate");
    const userSubscription = localStorage.getItem("subscriptionStatus");
    const [imageExists, setImageExists] = useState(false);


    const photoUrl = localStorage.getItem('photoURL');


    useEffect(() => {
        const checkImage = (url) => {
            const img = new Image();
            img.onload = () => setImageExists(true);
            img.onerror = () => setImageExists(false);
            img.src = url;
        };
        if (photoUrl) {
            checkImage(photoUrl);
        } else {
            setImageExists(false);
        }
    }, [photoUrl]);

    const goLogOut = () => {
        const data = {
            success: true,
            reason: 'no_reason'
        };

        if (window.kmpJsBridge && typeof window.kmpJsBridge.callNative === 'function') {
            window.kmpJsBridge.callNative(
                "LogOut",
                JSON.stringify(data),
                function (response) {
                    document.getElementById("subtitle").innerText = response;
                    console.log("Response from Native: " + response);
                }
            );
        } else {
            localStorage.clear();
            console.log("Logout");
        }
        navigate('/');
    };

    return (
        <div className="account-info-block profile-block-main">
            <div className="info-list-account-info">
                <span>{email}</span>
                <div>
                    <div className="red-point-console-main"
                         style={{backgroundColor: userSubscription === "ACTIVE" ? "#2A8500" : (userSubscription === 'CANCELED' && localStorage.getItem('subscriptionIsActive') === 'true') ? "#E4A400" : "#E40000"}}>
                    </div>
                    {subscriptionIsActive ? (
                        <span>Подписка до {expirationDate}</span>
                    ) : (
                        <span>Нет активной подписки</span>
                    )}
                </div>
                <button onClick={goLogOut}>Сменить аккаунт</button>
            </div>
            <img src={imageExists ? photoUrl : profile} alt="User Profile" />
        </div>
    );
};

export default UserInfoBlock;
