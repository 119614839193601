import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";


import "../CSS/Start_page_new.css";
import "../CSS/Start_page_new_mobile.css";
import "../CSS/Profile_new.css";
import "../CSS/Profile_new_mobile.css";
import '../../languages/i18n';
import 'react-toastify/dist/ReactToastify.css';

import ReferralBlock from "./JS_moments/Referral_block";
import ShopBlock from "./JS_moments/Shop_block";
import SubscriptionCancelledBlock from "./JS_moments/Subscription_cancelled_block";
import SubscriptionActiveBlock from "./JS_moments/Subscription_active_block";
import DevicesBlock from "./JS_moments/Devices_block";
import AppDownloadBlock from "./JS_moments/App_download_block";
import SupportBlock from "./JS_moments/Support_block";
import UserInfoBlock from "./JS_moments/User_info_block";

function Profile_all() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [userData, setUserData] = useState([]);
    const [userSubscription, setUserSubscription] = useState("NOT_ACTIVE");
    const [deviceData, setDeviceData] = useState([]);
    const [availableDevices, setAvailableDevices] = useState(0);
    const [shopData, setShopData] = useState([]);

    useEffect(() => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
        };
        fetchLoginData(requestData);
    }, []);

    useEffect(() => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
        };
        fetchShopData(requestData);
    }, []);


    const fetchLoginData = (requestData) => {
        fetch(`https://gostlink.com/api/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.text())
            .then((text) => {
                if (text) {
                    const data = JSON.parse(text);
                    if (data.success) {
                        localStorage.setItem('expirationDate', data.userInfo.expirationDate);
                        localStorage.setItem('subscriptionIsActive', data.userInfo.subscriptionIsActive);
                        localStorage.setItem('subscriptionStatus', data.userInfo.subscriptionStatus);
                        setUserData(data.userInfo)
                        setUserSubscription(data.userInfo.subscriptionStatus)
                        setDeviceData(data.devices);
                        setAvailableDevices(data.availableDevices);
                        console.log(data)
                    } else {
                        // Handle failure
                    }
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error);
                // Handle error
            });
    };

    const fetchShopData = (userData) => {

        fetch(`https://gostlink.com/api/shop/getShop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
            .then(response => {
                console.log(response);
                return response.text();
            })
            .then(text => {
                console.log(text);
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                console.log(data);
                if (data.success) {
                    setShopData(data);
                } else {
                    navigate(`/`);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                navigate(`/`);
            });
    };

    return (
        <div className="user-console-page">
            <div className="background-top-user-console background-main">
                <div className="gradient-top-user-console gradient-main"></div>
                {/*<div className="noise-top-user-console noise-main"/>*/}
            </div>
            <div className="background-bottom-user-console background-main">
                <div className="gradient-bottom-user-console gradient-main"></div>
                {/*<div className="noise-bottom-user-console noise-main"/>*/}
            </div>
            <div className="noise-main"/>

            <div className="user-console-content-main">
                <section className="account-info-main">
                    <UserInfoBlock/>
                </section>

                {(userSubscription === 'NOT_ACTIVE' || userSubscription === 'EXPIRED ' || (userSubscription === 'CANCELED' && localStorage.getItem('subscriptionIsActive') === 'false')) &&
                    <section className="user-console-main">
                        <ShopBlock shopData={shopData} refreshShopData={fetchShopData} />
                        <SupportBlock/>
                    </section>
                }
                {(userSubscription === 'CANCELED' && localStorage.getItem('subscriptionIsActive') === 'true') &&
                    <section className="user-console-main">
                        <ReferralBlock/>
                        <SubscriptionCancelledBlock shopData={shopData}/>
                        <DevicesBlock deviceData={deviceData} availableDevices={availableDevices} setDeviceData={setDeviceData} setAvailableDevices={setAvailableDevices}/>
                        <AppDownloadBlock/>
                        <SupportBlock/>
                    </section>
                }
                {userSubscription === 'ACTIVE' &&
                    <section className="user-console-main">
                        <ReferralBlock/>
                        <SubscriptionActiveBlock userData={userData}/>
                        <DevicesBlock deviceData={deviceData} availableDevices={availableDevices} setDeviceData={setDeviceData} setAvailableDevices={setAvailableDevices}/>
                        <AppDownloadBlock/>
                        <SupportBlock/>
                    </section>
                }
            </div>
            <section className="footer-section section-main" style={{paddingTop: '50px'}}>
                <div className="background-footer background-main">
                    <div className="gradient-footer gradient-main"></div>
                    <div className="noise-footer noise-main"></div>
                </div>
                <hr/>
                <div className="content-footer">
                    <span>© 2021 — 2025 ООО&nbsp;&nbsp;&nbsp;&nbsp;"Каннт"&nbsp;&nbsp;&nbsp;&nbsp;ИНН: 4011033196</span>
                    <button className="button-line-main button-footer"
                            onClick={() => {navigate('/offer')}}>
                        <span>
                            Публичная оферта
                        </span>
                    </button>
                </div>
            </section>
        </div>
    );
}

export default Profile_all;
