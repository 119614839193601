import React, {useEffect, useState} from 'react';
import '../CSS/Login_new.css';
import '../CSS/Start_page_new.css';
import '../CSS/Offer_new.css';
import { useTranslation } from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {CgArrowLeft, CgChevronDown} from "react-icons/cg";


function StartPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');

        if (token) {
            localStorage.setItem('promo', token);
        }
    }, [location.search]);


    return (
        <div className="page-container">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />

            <section className="offer-section section-main">
                <div className="background-product-info background-main">
                    <div className="gradient-product-info gradient-main"></div>
                    <div className="noise-product-info noise-main"/>
                </div>

                <div className="button-back-offer">
                    <button className="button-line-main"
                            onClick={() => {navigate(-1)}}>
                        <CgArrowLeft style={{marginRight: '7px'}} size={28}/>
                        <span>
                            Назад
                        </span>
                    </button>
                </div>

                <div className="offer-text-block">


                    <h1>ПУБЛИЧНАЯ ОФЕРТА</h1>
                    <h1>ООО «КАННТ»</h1>

                    <p>
                        <b>Общество с ограниченной ответственностью «КАННТ» (ООО «КАННТ»)</b> в лице Генерального
                        директора Чернятовича Станислава Юрьевича, действующего на основании Устава, именуемое в
                        дальнейшем <b>«Продавец»</b>, с одной стороны, и пользователь сети Интернет, именуемый в
                        дальнейшем <b>«Покупатель»</b>, с другой стороны, совместно именуемые «Стороны», заключили
                        настоящий договор (далее – «Договор»).
                    </p>
                    <p>
                        Договор, в соответствии со статьей 435 и пунктом 2 статьи 437 Гражданского кодекса Российской
                        Федерации, является публичной офертой (предложением) в адрес неограниченного числа лиц,
                        пользователей сети Интернет. В соответствии со статьей 438 Гражданского Кодекса Российской
                        Федерации, полным и безоговорочным акцептом (принятием) Договора является подтверждение
                        Покупателем своего согласия с условиями Договора или осуществление им платежа или начало
                        использования Покупателем услугами Продавца на условиях Договора или Дополнительных
                        Соглашений. Совершение любого из указанных действий означает также согласие Покупателя на
                        предоставление своего адреса электронной почты.
                    </p>
                    <p>
                        <b>1. Определение Терминов</b>
                    </p>
                    <p>
                        1.1. Публичная оферта (далее – Оферта) - публичное предложение Продавца, адресованное
                        неопределенному кругу лиц, заключить с Продавцом Договор оказания услуг дистанционным способом
                        (далее -«Договор») на условиях, содержащихся в настоящей Оферте, включая все Приложения.
                    </p>
                    <p>
                        1.2. Заказ – решение Заказчика заказать Услугу обработки данных через
                        интернет-сайт <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a> посредством
                        выбора одного из тарифов и его оплаты, является полным и безоговорочным акцептом Заказчиком настоящей Оферты.
                    </p>
                    <p>
                        1.3. Продавец предоставляет Услуги по шифрованию данных, включая услуги цифрового прокси сервиса,
                        а также иные Услуги, относящиеся к частной безопасности в сети Интернет и частному доступу к сети
                        Интернет (далее – Услуги).

                    </p>
                    <p>
                        1.4. Тарифы – система цен за Услуги обработки данных, представленная на
                        интернет-сайте <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a>.
                    </p>
                    <p>
                        <b>2. Общие Положения</b>
                    </p>
                    <p>
                        2.1. Покупатель осуществляет Заказ Услуг через
                        интернет-сайт <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a> посредством
                        выбора одного из представленных на интернет-сайте тарифов. Оплатой Заказа Покупатель подтверждает
                        согласие на заключение настоящего Договора (акцепт оферты).
                    </p>
                    <p>
                        2.2. Заключая Договор, Покупатель подтверждает следующее:
                    </p>
                    <p>
                        2.2.1. Покупатель целиком и полностью ознакомлен, и согласен с условиями настоящего Договора;
                    </p>
                    <p>
                        2.2.2. Покупатель дает разрешение на сбор, обработку и передачу данных об адресе его электронной
                        почты.
                    </p>
                    <p>
                        2.2.3. Покупатель соглашается на получение от Продавца информационных писем о скором окончании
                        подписки и об акциях, проводимых Продавцом.
                    </p>
                    <p>
                        <b>3. Цена Услуг</b>
                    </p>
                    <p>
                        3.1. Цена на Услуги указана на странице
                        интернет-сайта <a href="https://gostlink.com/shop" target="_blank">https://gostlink.com/shop</a>. Для
                        доступа к странице необходимо пройти авторизацию на
                        странице <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a>. После оплаты
                        стоимости подписки размер и дата следующего списания отображены в личном кабинете на
                        странице <a href="https://gostlink.com/profile" target="_blank">https://gostlink.com/profile</a>.
                    </p>
                    <p>
                        3.2. Продавец имеет право в одностороннем порядке изменить цену на любой Тариф.
                    </p>
                    <p>
                        3.3. Изменение Продавцом цены на оплаченный Заказ не допускается.
                    </p>
                    <p>
                        3.4. Обязательства Покупателя по оплате Заказа считаются исполненными с момента поступления
                        Продавцу денежных средств в соответствии с Тарифами в полном размере.
                    </p>
                    <p>
                        3.5. Расчеты между Продавцом и Покупателем за Товар производятся способами, указанными на 
                        сайте <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a>.
                    </p>
                    <p>
                        <b>4. Оформление Заказа</b>
                    </p>
                    <p>
                        4.1. Заказ Услуг осуществляется Покупателем через сайт <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a>.
                    </p>
                    <p>
                        4.2. При заказе на интернет-сайте <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a> Покупатель
                        обязуется предоставить адрес электронной почты.
                    </p>
                    <p>
                        4.3. Если Продавцу необходима дополнительная информация, он вправе запросить ее у Покупателя. В
                        случае непредоставления необходимой информации Покупателем, Продавец не несет ответственности
                        за предоставление качественной услуги Покупателю.
                    </p>
                    <p>
                        4.4. Покупатель несет ответственность за достоверность предоставленной информации при оформлении
                        и оплате Заказа.
                    </p>
                    <p>
                        4.5. Договор между Продавцом и Покупателем считается заключенным с момента электронного
                        оформления заказа на интернет-сайте https://gostlink.com и его оплаты Покупателем.
                    </p>
                    <p>
                        4.6. Если Покупатель не воспользовался формой «Отмена подписки» на
                        интернет-сайте <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a>, оформленный
                        Покупателем Заказ продлевается на аналогичный срок по истечению срока действия первоначального
                        Заказа Покупателя в соответствии с Тарифами, указанными на
                        интернет-сайте <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a>, на
                        дату такого продления (авто-продление оказания Услуг).

                    </p>
                    <p>
                        <b>5. Прекращение Оказания Услуг</b>
                    </p>
                    <p>
                        5.1. Покупатель вправе отказаться от Услуг в любое время действия Договора. Фактически оказанные
                        Услуги Покупатель оплачивает в полном размере в соответствии с Тарифами до даты отказа Покупателя
                        от Услуг. При этом если Покупатель отказывается от Услуг в течение действия оплаченного периода, то
                        Услуги будут оказываться до окончания действия периода, авто-продление оказания Услуг при этом не
                        производится.
                    </p>
                    <p>
                        5.2. Если Покупатель не обратился к Продавцу в письменном виде по адресам, указанным на
                        интернет-сайте <a href="https://gostlink.com/" target="_blank">https://gostlink.com/</a> с
                        предложением о прекращении Договора Договор признается действующим до его прекращения по
                        соглашению Сторон.
                    </p>
                    <p>
                        5.3. Продавец вправе ограничить Покупателю предоставление Услуг в случае выявления фактов
                        недобросовестного использования Сервиса, а именно:
                    </p>
                    <p>
                        5.3.1. Использование Сервиса Покупателем в целях нарушения законодательства Российской
                        Федерации или других государств;
                    </p>
                    <p>
                        5.3.2. Передачу индивидуального ключа доступа третьим лицам (за исключением близких
                        родственников Покупателя);
                    </p>
                    <p>
                        5.3.3. Продавец оставляет за собой право досрочно завершить пробный (акционный) период, и
                        перевести Покупателя на полный тариф без дополнительных уведомлений в случае выявления
                        неоднократного использования пробного (акционного) периода.
                    </p>
                    <p>
                        5.4. Претензии рассматриваются в порядке и в сроки, установленные действующим законодательством
                        Российской Федерации.
                    </p>
                    <p>
                        5.5. В случае недостижения согласия между Покупателем и Продавцом в связи с предъявленной
                        претензией, спор подлежит рассмотрению в судебном порядке в соответствии с действующим
                        законодательством Российской Федерации.
                    </p>
                    <p>
                        <b>6. Ответственность Сторон</b>
                    </p>
                    <p>
                        6.1. Покупатель обязуется не использовать систему для рассылки спама, сканирования портов и прокси,
                        осуществления массовой рассылки (даже если письма фактически отправляются через другой сервер),
                        не совершать хакерских атак на другие компьютеры или сети.
                    </p>
                    <p>
                        6.2. Зона покрытия, скорость интернета и качество связи при оказании Услуг могут варьироваться, но
                        никак не зависят от качества предоставления Услуг Продавцом. При возникновении проблем с зоной
                        покрытия, скоростью интернета и качеством связи Покупателю необходимо обращаться в поддержку
                        интернет-провайдера, который предоставляет услуги по доступу в интернет Покупателю.
                    </p>
                    <p>
                        6.3. Услуги могут быть недоступны вследствие возникновения не зависящих от Продавца факторов,
                        включая чрезвычайные ситуации, сетевые проблемы или ограничения, помехи, прерывание сигнала,
                        нарушения в работе других служб. Продавец не несет ответственности если Услуги не могут быть
                        предоставлены по вышеперечисленным причинам.

                    </p>
                    <p>
                        6.4. Продавец не несет ответственности за потерю данных и сообщений, неправильное отражение
                        страниц вследствие проблем с сетью. Для защиты Покупателей Продавец в одностороннем порядке
                        может устанавливать ограничения на использование определенных данных и блокировать
                        обслуживание клиентов.

                    </p>
                    <p>
                        6.5. Продавец не дает гарантий, касающихся своевременности получения данных, и не несет
                        ответственности за возможные задержки или потери
                    </p>
                    <p>
                        7. Заключительные положения
                    </p>
                    <p>
                        7.1. Во всем остальном, что не предусмотрено Договором, Стороны руководствуются действующим
                        законодательством Российской Федерации.
                    </p>
                    <p>
                        7.2. Продавец вправе использовать факсимильный способ воспроизведения подписи с помощью
                        средств механического или иного копирования, на что Покупатель дает свое согласие путем заключения
                        Договора.
                    </p>
                    <p>
                        <b>8. Адрес и платежные реквизиты Продавца</b>
                    </p>
                    <p>
                        Юридический адрес: 249087, Калужская область, М.Р-Н Малоярославецкий, С.П. поселок Юбилейный,
                        П. Юбилейный, УЛ. Советская, Д. 2, ПОМЕЩ. №22, ОФИС №2
                    </p>
                    <p>
                        ОГРН 1214000003029 ИНН 4011033196 КПП 401101001
                    </p>
                    <p>
                        Банк: АО "АЛЬФА-БАНК" БИК: 044525593
                    </p>
                    <p>
                        Кор. счёт: 30101810200000000593
                    </p>
                    <p>
                        Номер счёта: 40702810402620007691
                    </p>

                    <button className="button-line-main button-download-offer"
                            onClick={() => {window.open('https://gostlink.com/api/get/docs/oferta_kannt.pdf');}}>
                        <span>
                            Скачать документ оферты
                        </span>
                    </button>
                </div>
            </section>
        </div>
    );
}

export default StartPage;
