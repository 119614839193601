import React, {useState, useEffect, useRef} from 'react';
import '../../languages/i18n';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useTranslation} from "react-i18next";
import UserInfoBlock from "./JS_moments/User_info_block";
import HoldButton from "./JS_moments/Hold_button";


function Cancel_subscription_new() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const {t} = useTranslation();
    const [userSubscription, setUserSubscription] = useState("NOT_ACTIVE");



    useEffect(() => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
        };
        fetchLoginData(requestData);
    }, []);

    useEffect(() => {
        if (userData.subscriptionIsActive) {
            if (userSubscription !== 'ACTIVE') {
                navigate('/profile');
            }
        }
    }, [userData, navigate]);

    const fetchLoginData = (requestData) => {
        fetch(`https://gostlink.com/api/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.text())
            .then((text) => {
                if (text) {
                    const data = JSON.parse(text);
                    if (data.success) {
                        localStorage.setItem('expirationDate', data.userInfo.expirationDate);
                        localStorage.setItem('subscriptionIsActive', data.userInfo.subscriptionIsActive);
                        setUserData(data.userInfo)
                        setUserSubscription(data.userInfo.subscriptionStatus)
                    } else {
                        // Handle failure
                    }
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error);
            });
    };

    function cancelSubscription() {
        const email = localStorage.getItem('email');
        const uid = localStorage.getItem('uid');
        if (!email) {
            console.error("Email отсутствует, запрос не отправлен.");
            return;
        }

        const body = {
            email: email,
            ...(uid && { uid: uid }) // Добавляем uid только если он есть
        };

        fetch('https://gostlink.com/api/subscriptions/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response => {
                if (response.ok) {
                    console.log("Запрос успешно отправлен");
                    navigate('/profile');
                } else {
                    console.error("Ошибка при отправке запроса");
                }
            })
            .catch(error => {
                console.error("Произошла ошибка:", error);
            });
    }


    return (
        <div className="user-console-page">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />
            <div className="user-console-content-main">
                <section className="account-info-main">
                    <UserInfoBlock/>
                </section>

                <section className="user-console-main">
                    <div className="cancel-description-user-console profile-block-main">
                        <div className="title-profile-block-main">
                            <div className="group-elements-title-profile-main">
                                <h1>
                                    Отмена подписки
                                </h1>
                            </div>
                            <hr/>
                        </div>
                        <div className="content-block-cancel-description content-profile-block-main">
                            <span className="text-condolences-cancel-description">
                                Мы не плачем... просто это дождь в нашем сердце. Если мы сделали что-то не так, дайте знать - возможно, мы успеем исправить ситуацию!
                            </span>
                            <span>
                                Вы решили уйти окончательно, помните: вы всегда можете вернуться, но и промокоды - не вечные.
                            </span>
                            <HoldButton
                                target={() => cancelSubscription()}
                                className={'cancel-button-cancel-description'}
                                text={'Отменить подписку'}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Cancel_subscription_new;
