import React, {useEffect, useState} from 'react';
import '../CSS/Login_new.css';
import '../CSS/Start_page_new.css';
import { useTranslation } from "react-i18next";
import SignInButton from "../../js/elements/SignInButton";
import {useLocation, useNavigate} from "react-router-dom";
import Yandex from "../../images/YandexLogo.svg";
import YandexAuth from "../../registration/YandexAuth";
import {ToastContainer} from "react-toastify";
import {CgArrowLeft, CgChevronDown} from "react-icons/cg";


function StartPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');

        if (token) {
            localStorage.setItem('promo', token);
        }
    }, [location.search]);


    return (
        <div className="page-container">
            <ToastContainer toastStyle={{ color: '#fff', backgroundColor: '#333' }} />

            <section className="login-section section-main">
                <div className="background-product-info background-main">
                    <div className="gradient-product-info gradient-main"></div>
                    <div className="noise-product-info noise-main"/>
                </div>
                <div className="login-block">
                    <h1 className="title-login-block">Войти или зарегистрироваться</h1>
                    <div className="button-block-login">
                        <SignInButton/>
                        <YandexAuth/>
                    </div>
                    <button className="button-back-main-page button-line-main"
                            onClick={() => {navigate('/')}}>
                        <CgArrowLeft style={{marginRight: '7px'}} size={28}/>
                        <span>
                            Вернуться
                        </span>
                    </button>
                </div>


            </section>
        </div>
    );
}

export default StartPage;
