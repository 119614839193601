import './css/App.css';
import './css/Main.css';
import './css/PromoNew.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from "./js/StartPage.js";
import Shop from "./js/Shop.js";
import ShopTest from "./js/Shop_test.js";
import Background from "./background/Background";
import YAuth from "./registration/YAuth";
import ShopVMobile from "./js/ShopVMobile";
import Profile from "./js/Profile";
import Promo from "./js/Promo";
import PromoNew from "./js/PromoNew";
import Dolor from "./js/Dolor";
import YAuthMobile from "./registration/YAuthMobile";
import Signature from "./js/elements/Signature";
import ProfileVMobile from "./js/ProfileVMobile";
import PanelLogin from "./panel/PanelLogin";
import PanelSearch from "./panel/PanelSearch";
import PanelUser from "./panel/PanelUser";
import PanelReceipts from "./panel/PanelReceipts";
import PanelInvited from "./panel/PanelInvited";
import PanelLogs from "./panel/PanelLogs";
import './languages/i18n';
import PanelPromo from "./panel/PanelPromo";
import PanelSubscription from "./panel/PanelSubscription";
import StartPageNew from "./js/StartPageNew";
import Login from "./js/Login";

import Start_page_new from "./New_design/JS/Start_page_new";
import Profile_new from "./New_design/JS/Profile_new";
import Profile_all from "./New_design/JS/Profile_all_new";
import Login_new from "./New_design/JS/Login_new";
import Cancel_subscription_new from "./New_design/JS/Cancel_subscription_new";
import Promo_new from "./New_design/JS/Promo_new";
import Offer_new from "./New_design/JS/Offer_new";
import Mobile_shop from "./New_design/JS/Modile_pages/Mobile_shop";
import Mobile_profile from "./New_design/JS/Modile_pages/Mobile_profile";

function App() {

    return (
        <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{width: '100%'}}>
                <Router>
                    <Routes>
                        <Route path="/" element={<Start_page_new/>}/>
                        <Route path="" element={<Start_page_new/>}/>
                        <Route path="/tt" element={<Start_page_new/>}/>
                        <Route path="/yt" element={<Start_page_new/>}/>

                        <Route path="/login" element={<Login_new/>}/>
                        <Route path="/profile" element={<Profile_new/>}/>
                        <Route path="/promo" element={<Promo_new/>}/>
                        <Route path="/cancel" element={<Cancel_subscription_new/>}/>

                        <Route path="/offer" element={<Offer_new/>}/>

                        <Route path="/yauth" element={<YAuth/>}/>
                        <Route path="/yauth-mobile" element={<YAuthMobile/>}/>

                        <Route path="/mobileShop" element={<Mobile_shop/>}/>
                        <Route path="/mobileProfile" element={<Mobile_profile/>}/>

                        {/*<Route path="/profile-all" element={<Profile_all/>}/>*/}
                        <Route
                            path = "*"
                            element = {
                                <>
                                    <Background />
                                    <Routes>
                                        {/*<Route path="/promo" element={<Promo/>}/>*/}
                                        {/*<Route path="/promo-new" element={<PromoNew/>}/>*/}
                                        {/*<Route path="/profile" element={<Profile/>}/>*/}

                                        {/*<Route path="/mobileShop" element={<ShopVMobile/>}/>*/}
                                        {/*<Route path="/mobileProfile" element={<ProfileVMobile/>}/>*/}

                                        {/*<Route path="/yauth" element={<YAuth/>}/>*/}
                                        {/*<Route path="/yauth-mobile" element={<YAuthMobile/>}/>*/}
                                        {/*<Route path="/dolor" element={<Dolor/>}/>*/}

                                        {/*<Route path="/login" element={<Login/>}/>*/}

                                        <Route path="/panel-login" element={<PanelLogin/>}/>
                                        <Route path="/panel-search" element={<PanelSearch/>}/>
                                        <Route path="/panel-user" element={<PanelUser/>}/>
                                        <Route path="/panel-receipts" element={<PanelReceipts/>}/>
                                        <Route path="/panel-invited" element={<PanelInvited/>}/>
                                        <Route path="/panel-logs" element={<PanelLogs/>}/>
                                        <Route path="/panel-promo" element={<PanelPromo/>}/>
                                        <Route path="/panel-subscription" element={<PanelSubscription/>}/>

                                        {/*<Route path="/shop" element={<Shop/>}/>*/}
                                        {/*<Route path="/s-elovim-spasom" element={<ShopTest/>}/>*/}
                                        {/*<Route path="/tt" element={<StartPageNew/>}/>*/}
                                        {/*<Route path="/yt" element={<StartPageNew/>}/>*/}
                                        {/*<Route path="" element={<StartPageNew/>}/>*/}
                                        {/*<Route path="/" element={<StartPageNew/>}/>*/}
                                    </Routes>
                                    <Signature />
                                </>
                            }
                        />
                    </Routes>
                </Router>
            </div>
        </div>
    );
}
export default App;