import React, { useState, useEffect } from 'react';

import "../CSS/Promo_new.css";
import "../CSS/Promo_new_mobile.css";
import "../CSS/Profile_new.css";
import "../CSS/Profile_new_mobile.css";
import 'react-toastify/dist/ReactToastify.css';

import '../../languages/i18n';

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { QRCodeCanvas } from 'qrcode.react';
import {useTranslation} from "react-i18next";
import {PulseLoader} from "react-spinners";
import UserInfoBlock from "./JS_moments/User_info_block";

function PromoNew() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [Refferal, setRefferal] = useState("");

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .catch((error) => {
                console.error('Failed to copy: ', error);
            });
        toast.success("Скопировано в буфер обмена");
    };

    const handleShareQr = async (shareMessage, shareUrl) => {
        try {
            const canvas = document.querySelector('.Promo-block-qr-code > canvas');
            const imageUrl = canvas.toDataURL('image/png');

            if (navigator.share) {
                await navigator.share({
                    title: 'Поделиться',
                    text: shareMessage,
                    url: shareUrl,
                    files: [new File([await fetch(imageUrl).then(r => r.blob())], 'gostlink_qr.png', { type: 'image/png' })],
                });
            } else {
                toast.error('Ваше устройство не поддерживает функцию "Поделиться".');
            }
        } catch (error) {
            if (error && error.length > 0) {
                toast.error('Ошибка при попытке поделиться:', error);
            }
        }
    };

    const handleShare = async (shareMessage, shareUrl) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Поделиться',
                    text: shareMessage,
                    url: shareUrl,
                });
            } else {
                toast.error('Ваше устройство не поддерживает функцию "Поделиться".');
            }
        } catch (error) {
            if (error && error.length > 0) {
                toast.error('Ошибка при попытке поделиться:', error);
            }
        }
    };

    useEffect(() => {
        const requestData = {
            uid: localStorage.getItem('uid'),
            email: localStorage.getItem('email'),
        };
        fetchPromo(requestData);
    }, []);

    const handleDownload = () => {
        const canvas = document.querySelector('.Promo-block-qr-code > canvas');
        const imageUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'gostlink_qr.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const fetchPromo = (requestData) => {
        fetch(`https://gostlink.com/api/profile/getReferralCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then(response => response.text())
            .then(text => {
                if (text) {
                    return JSON.parse(text);
                }
                return {};
            })
            .then(data => {
                if (data.success) {
                    localStorage.setItem('referral', data.referralCode);
                    setRefferal(data.referralCode);
                } else {
                    navigate(`/`);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                navigate(`/`);
            });
    };

    return (
        <div className="user-console-page">
            <ToastContainer toastStyle={{ color: '#ffffff', backgroundColor: '#333333' }} />
            <div className="background-top-user-console background-main">
                <div className="gradient-top-user-console gradient-main"></div>
                {/*<div className="noise-top-user-console noise-main"/>*/}
            </div>
            <div className="background-bottom-user-console background-main">
                <div className="gradient-bottom-user-console gradient-main"></div>
                {/*<div className="noise-bottom-user-console noise-main"/>*/}
            </div>
            <div className="noise-main"/>

            <div className="user-console-content-main">
                <section className="account-info-main">
                    <UserInfoBlock/>
                </section>

                {Refferal.length > 10 ? (
                    <section className="user-console-main">
                        <div className="title-promo-page">
                            <span>Приглашай<br/>друзей<br/>и получай<br/>скидку</span>
                            <span>до 100%</span>
                        </div>

                        <div className="section-step-promo">
                            <div className="number-step-promo">
                                <span>
                                    1
                                </span>
                            </div>
                            <div className="description-step-promo">
                                <span>
                                    Поделись своей уникальной ссылкой-приглашением в сервис GOSTLINK со знакомым, друзьями или семьей
                                </span>
                            </div>
                        </div>

                        <div className="section-step-promo">
                            <div className="number-step-promo">
                                <span>
                                    2
                                </span>
                            </div>
                            <div className="description-step-promo">
                                <span>
                                    Когда твой приглашенный начнет пользоваться сервисом, ты получишь скидку на оплату подписки в 25% от полной стоимости
                                </span>
                            </div>
                        </div>

                        <div className="section-step-promo">
                            <div className="number-step-promo">
                                <span>
                                    3
                                </span>
                            </div>
                            <div className="description-step-promo">
                                <span>
                                    Скидки от нескольких приглашенных складываются (не более 100%), время проведения акции не ограничено
                                </span>
                            </div>
                        </div>

                        <div className="info-promo-page">
                            <div className="QR-info-promo-page" onClick={() => handleShareQr("Сервис gostlink для безопасного и быстрого доступа в интернет ", "https://gostlink.com/?token=" + Refferal)}>
                                <QRCodeCanvas
                                    value={"https://gostlink.com/?token=" + Refferal}
                                    size={256}
                                    bgColor={"#FFFFFF"}
                                    fgColor={"#000000"}
                                    level={"H"}
                                    marginSize={4}
                                />
                            </div>
                            <div className="buttons-info-promo-page">
                                <button className="button-user-console-main" onClick={() => handleShare("Сервис gostlink для безопасного и быстрого доступа в интернет ", "https://gostlink.com/?token=" + Refferal)}>
                                    Поделиться
                                </button>
                                <button className="button-user-console-main" onClick={() => copyToClipboard("https://gostlink.com/?token=" + Refferal)}>
                                    Скопировать ссылку
                                </button>
                                <button className="button-user-console-main" onClick={handleDownload}>
                                    Скачать QR код
                                </button>
                            </div>
                        </div>

                    </section>
                ) : (
                    <section className="loading-spinner">
                        <PulseLoader color="#FFF" loading={true} size={10} />
                    </section>
                )}
            </div>
            <section className="footer-section section-main" style={{paddingTop: '50px'}}>
                <div className="background-footer background-main">
                    <div className="gradient-footer gradient-main"></div>
                    <div className="noise-footer noise-main"></div>
                </div>
                <hr/>
                <div className="content-footer">
                    <span>© 2021 — 2025 ООО&nbsp;&nbsp;&nbsp;&nbsp;"Каннт"&nbsp;&nbsp;&nbsp;&nbsp;ИНН: 4011033196</span>
                    <button className="button-line-main button-footer"
                            onClick={() => {navigate('/offer')}}>
                        <span>
                            Публичная оферта
                        </span>
                    </button>
                </div>
            </section>
        </div>
    );
}

export default PromoNew;

